













































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import type { Campaign } from '@/core/campaign/models/Campaign';
import { copyTextToClipboard } from '@/ui/util/clipboard';
import i18n from '@/i18n';
import { AdminStoreMutations } from '@/ui/stores/AdminStore';
import { NavigationMixin } from '@/ui/mixins/NavigationMixin';
import { ROUTE_CAMPAIGN_DETAIL, ROUTE_EDIT_CAMPAIGN } from '@/ui/router/campaign.routes';

@Component({
  name: 'CampaignDataTable',
})
export default class CampaignDataTable extends Mixins(NavigationMixin) {
  @Prop() search: string | undefined;
  @Prop() campaigns!: Campaign[];
  private dateOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  private locale = i18n.i18next.language;
  page = 1;
  pageCount = 0;

  private get headers() {
    return [
      { text: this.$t('Admin.campaign.campaign'), value: 'title' },
      { text: this.$t('Admin.campaign.startDate'), value: 'startDate' },
      { text: this.$t('Admin.campaign.endDate'), value: 'endDate' },
      { text: this.$t('Admin.campaign.id'), value: 'id' },
      { text: this.$t('Admin.campaign.creation-date'), value: 'createdOn' },
      { text: '', width: '10%', value: 'actions' },
    ];
  }

  private async copyToClipboard(item: Campaign) {
    await copyTextToClipboard(item.id);
  }

  private editCampaign(campaign: Campaign) {
    this.$store.commit(AdminStoreMutations.SET_CAMPAIGN_TO_EDIT, campaign);
    this.$store.commit(AdminStoreMutations.SET_IS_ADMIN_CREATE_CAMPAIGN, true);
    this.$emit('updateLastSavedParentRouteForEditCampaign', this.$route.name);
    this.goTo(ROUTE_EDIT_CAMPAIGN);
  }

  private getDate(isoString: string) {
    return new Date(isoString).toLocaleString(this.locale, this.dateOptions);
  }

  private rowClicked(item: Campaign): void {
    this.$emit('updateLastSavedParentRoute', this.$route.name);
    this.goTo(ROUTE_CAMPAIGN_DETAIL, { campaignId: item.id });
  }
}
