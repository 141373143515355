var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"jls-data-table pointer",attrs:{"page":_vm.page,"hide-default-footer":"","headers":_vm.headers,"items":_vm.campaigns,"search":_vm.search},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event},"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"header.title",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" ")]}},{key:"header.startDate",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" ")]}},{key:"header.endDate",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" ")]}},{key:"header.id",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" ")]}},{key:"header.createdOn",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" ")]}},{key:"header.actions",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" ")]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.getDate(item.startDate))+" ")])]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.getDate(item.endDate))+" ")])]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.createdOn",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.getDate(item.createdOn))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"campaign-admin-icons-wrapper"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.copyToClipboard(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"accent"}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('shared.common.tooltip-copy-ID')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.editCampaign(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"accent"}},[_vm._v(" mdi-pencil-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('shared.common.tooltip-edit')))])])],1)]}}],null,true)}),_c('div',{staticClass:"pagination-wrapper"},[_c('v-pagination',{staticClass:"jls-data-table-footer",attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }