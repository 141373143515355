




























































import { Component, Vue } from 'vue-property-decorator';
import CampaignDataTable from '@/ui/components/molecules/tables/CampaignDataTable.vue';
import type { Campaign } from '@/core/campaign/models/Campaign';
import AdminCampaignsFilter from '@/ui/components/organisms/filter/AdminCampaignsFilter.vue';
import { CampaignMedia } from '@/core/campaign/models/CampaignMedia';
import { AdminStoreMutations } from '@/ui/stores/AdminStore';
import { Loader } from '@/ui/util/decorators/loader';
import type { CurrentUser } from '@/core/user/models/CurrentUser';
import LabelChip from '@/ui/components/atoms/groupingItems/LabelChip.vue';
import { AlertStoreActions, AlertType } from '@/ui/stores/AlertStore';
import { PermanentStoreMutations } from '@/ui/stores/PermanentStore';
import ButtonWithTextAndIcon from '@/ui/components/atoms/buttons/ButtonWithTextAndIcon.vue';

@Component({
  name: 'AdminCampaignTab',
  components: { LabelChip, CampaignDataTable, ButtonWithTextAndIcon, AdminCampaignsFilter },
})
export default class AdminCampaignTab extends Vue {
  private searchTerm = '';
  private activeSearchTerm = '';
  private showSearchTag = false;
  private isAdmin = true;

  async created() {
    return this.campaigns?.length > 0
      ? this.campaigns
      : this.fetchNewCampaignData().then(() => this.areCampaignsAvailable());
  }

  mounted() {
    this.activeSearchTerm = this.$store.state.adminStore.selectedSearchTerm;
    if (this.activeSearchTerm != undefined && this.activeSearchTerm != '') {
      this.showSearchTag = true;
    }
  }

  get selectedSubjectFilter() {
    return this.$store.state.adminStore.selectedSubjectTags || [];
  }

  get selectedLabelFilter() {
    return this.$store.state.adminStore.selectedGeneralTags || [];
  }

  get selectedAdministrativeZones() {
    return this.$store.state.adminStore.selectedAdministrativeZones;
  }

  get selectedDates() {
    return this.$store.state.adminStore.selectedDates || [];
  }

  get fetchTags() {
    return this.currentUser
      ? this.isAdmin
        ? this.$store.state.adminStore.allZones
        : this.$store.state.campaignStore.allZones
      : [];
  }

  get campaigns(): Campaign[] {
    return this.$store.state.adminStore.campaigns;
  }

  get continuationToken(): number {
    return this.$store.state.adminStore.continuationToken;
  }

  get allCampaignMedia(): CampaignMedia[] {
    if (!this.campaigns) {
      return [];
    }
    return this.campaigns.flatMap((campaign) => campaign.media);
  }

  get currentUser(): CurrentUser | undefined {
    return this.$store.state.userStore.currentUser;
  }

  @Loader
  async fetchNewCampaignData() {
    await this.$store.dispatch(AdminStoreMutations.SET_CAMPAIGNS);
  }

  @Loader
  async loadMore() {
    await this.$store.dispatch(AdminStoreMutations.LOAD_MORE_CAMPAIGNS);
  }

  @Loader
  async updateSearchTerm() {
    this.$store.commit(AdminStoreMutations.SET_FILTER_SEARCH, this.searchTerm);
    await this.$store.dispatch(AdminStoreMutations.SET_CAMPAIGNS);
    this.areCampaignsAvailable();
    this.activeSearchTerm = this.searchTerm;
    this.searchTerm = '';
    this.showSearchTag = this.activeSearchTerm?.length > 0;
  }

  @Loader
  async updateSearchTermAfterRemovingLabel() {
    this.$store.commit(AdminStoreMutations.SET_FILTER_SEARCH, '');
    await this.$store.dispatch(AdminStoreMutations.SET_CAMPAIGNS);
    this.areCampaignsAvailable();
  }

  areCampaignsAvailable() {
    if (this.campaigns?.length === 0) {
      this.$store.dispatch(AlertStoreActions.TRIGGER_ALERT, {
        message: 'campaign.campaigns.no-campaigns',
        type: AlertType.WARNING,
      });
    }
  }

  resetSearchDisplay() {
    this.activeSearchTerm = '';
    this.searchTerm = '';
    this.showSearchTag = false;
  }

  removeLabel() {
    this.activeSearchTerm = '';
    this.showSearchTag = false;
    this.updateSearchTermAfterRemovingLabel();
  }

  updateLastSavedParentRoute(routeName: string) {
    this.$store.commit(PermanentStoreMutations.SET_LAST_SAVED_PARENT_ROUTE_FOR_CAMPAIGN_DETAILS, routeName);
  }

  updateLastSavedParentRouteForEditCampaign(routeName: string) {
    this.$store.commit(PermanentStoreMutations.SET_LAST_SAVED_PARENT_ROUTE_FOR_EDIT_CAMPAIGN, routeName);
    this.$store.commit(PermanentStoreMutations.SET_LAST_SAVED_PARENT_ROUTE_PARAMS_FOR_EDIT_CAMPAIGN, undefined);
  }
}
