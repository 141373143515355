































import { Component, Prop, Vue } from 'vue-property-decorator';
import LabelFilter from '@/ui/components/molecules/filter/LabelFilter.vue';
import DateFilter from '@/ui/components/molecules/filter/DateFilter.vue';
import ChannelFilter from '@/ui/components/molecules/filter/ChannelFilter.vue';
import SubjectFilter from '@/ui/components/molecules/filter/SubjectFilter.vue';
import getLogger from '@/shared/logger/logger';
import { CampaignMedia } from '@/core/campaign/models/CampaignMedia';
import type { Tag } from '@/core/tags/models/Tag';
import { AdminStoreActions, AdminStoreMutations } from '@/ui/stores/AdminStore';
import { Loader } from '@/ui/util/decorators/loader';
import { TagType } from '@/core/tags/enums/TagType';
import { findTagsUseCase } from '@/core/tags/usecases/findTagsUseCase';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOG = getLogger('AdminCampaignFilter');

@Component({
  name: 'AdminCampaignFilter',
  components: { DateFilter, ChannelFilter, LabelFilter, SubjectFilter },
})
export default class AdminCampaignFilter extends Vue {
  @Prop({ default: undefined }) campaignMedia: CampaignMedia[] | undefined;
  @Prop() zones: Tag[] | undefined;
  @Prop() selectedZones: Tag[] | undefined;
  @Prop() selectedDates: string[] | undefined;
  @Prop() selectedLabelFilter!: Tag[];
  @Prop() selectedSubjectFilter!: Tag[];
  private allGeneralTags: Array<Tag> = [];
  private allSubjectTags: Array<Tag> = [];

  async created() {
    if (!this.allAvailableCampaignZones) {
      await this.fetchZones();
    }
  }

  get allAvailableCampaignZones() {
    return this.$store.state.adminStore.allZones;
  }

  async fetchGeneralTags(search: string) {
    if (search.length === 0) this.allGeneralTags = [];
    else {
      this.allGeneralTags = await findTagsUseCase(
        { type: TagType.GENERAL, search: search },
        this.$store.state.userStore.currentUser,
      );
    }
  }

  @Loader
  async fetchZones() {
    await this.$store.dispatch(AdminStoreActions.GET_FILTER_ADMINISTRATIVE_ZONES);
  }

  @Loader
  async fetchSubjects(search: string) {
    if (search.length === 0) this.allSubjectTags = [];
    else {
      this.allSubjectTags = await findTagsUseCase(
        { type: TagType.SUBJECT, search: search },
        this.$store.state.userStore.currentUser,
      );
    }
  }

  @Loader
  async updateGeneralTags(tags: Tag[]) {
    this.$store.commit(AdminStoreMutations.SET_FILTER_GENERAL_TAGS, tags);
    await this.$store.dispatch(AdminStoreMutations.SET_CAMPAIGNS);
    this.$emit('updated');
  }

  @Loader
  async updateZones(zones: Tag[]) {
    this.$store.commit(AdminStoreMutations.SET_FILTER_ADMINISTRATIVE_ZONES, zones);
    await this.$store.dispatch(AdminStoreMutations.SET_CAMPAIGNS);
    this.$emit('updated');
  }

  @Loader
  async updateDates(dates: string[]) {
    this.$store.commit(AdminStoreMutations.SET_FILTER_DATES, dates);
    await this.$store.dispatch(AdminStoreMutations.SET_CAMPAIGNS);
    this.$emit('updated');
  }

  @Loader
  async updateSubjects(tags: Tag[]) {
    this.$store.commit(AdminStoreMutations.SET_FILTER_SUBJECT_TAGS, tags);
    await this.$store.dispatch(AdminStoreMutations.SET_CAMPAIGNS);
    this.$emit('updated');
  }

  @Loader
  async resetAllFilters() {
    this.$store.commit(AdminStoreMutations.RESET_ALL_FILTERS);
    await this.$store.dispatch(AdminStoreMutations.SET_CAMPAIGNS);
    this.$emit('resetSearch');
  }
}
